// show a loading spinner for a whole page
.loading {
  position: absolute;
  width: 100vw;
  height: 100vh;
  z-index: 9;
  display: flex;
  justify-content: center;
  align-items: center;
  background: $white;
  .gif {
    z-index: 99;
    width: 40px;
    height: 40px;
  }
}

// only show the loading spinner in a page section
.section-loading {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: $white;
  width: 100%;
  .gif {
    z-index: 99;
    width: 40px;
    height: 40px;
  }
}

.loader {
  width: 20px;
  height: 20px;
  background-color: white;
  border-radius: 50%;
}
