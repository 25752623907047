.settings-page-layout-wrapper {
  width: 100%;
  .inner-layout {
    display: flex;
    justify-content: center;
    width: auto;
    margin: 1rem;
    background: transparent;
    border-radius: 12px;
    @media screen and (min-width: map-get($grid-breakpoints, "md")) {
      margin: 1rem;
    }
  }
}

.settings-page {
  width: 100%;
  .settings-form {
    padding: 20px;
    border-radius: 10px;
    background: $white;
    @media screen and (min-width: map-get($grid-breakpoints, "sm")) {
    }
    @media screen and (min-width: map-get($grid-breakpoints, "md")) {
    }
    @media screen and (min-width: map-get($grid-breakpoints, "lg")) {
      margin-left: 20px;
    }
    .form {
      .form-field {
        .image-display {
          display: flex;
          padding: 10px;
          border-radius: 10px;
          .img {
            width: 150px;
            height: 150px;
            position: relative;
            overflow: hidden;
            border-radius: 10px;
            border: 1px dotted $grey;
            img {
              width: 100%;
              height: 100%;
              object-fit: scale-down;
            }
            .controls {
              display: none;
            }
            &:hover {
              img {
                opacity: 0.3;
              }
              .controls {
                display: block;
                position: absolute;
                width: 100%;
                height: 100%;
                top: 0;
                left: 0;
                label {
                  cursor: pointer;
                  width: 100%;
                  height: 100%;
                  .label-content {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: 100%;
                    height: 100%;
                  }
                }
              }
            }
          }
          .remove-image {
            display: flex;
            align-items: flex-end;
            margin-left: 10px;
            margin-bottom: 3px;
            cursor: pointer;
          }
        }
      }
    }
  }
}

.change-password-inner-div {
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  @media screen and (min-width: map-get($grid-breakpoints, "lg")) {
    margin-left: 20px;
  }
}

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  background-color: #e0f4f1 !important;
  color: #000000 !important;
}

@media (max-width: map-get($grid-breakpoints, "lg")) {
  .vp-1200 {
    flex-direction: column;
  }
}
