.page {
  background-color: $background-green;
  display: flex;
  min-width: 100%;
  min-height: 100vh;
  padding-bottom: 90px;
  @media screen and (min-width: map-get($grid-breakpoints, "sm")) {
    padding-bottom: 0;
  }
  .p-frozen-column {
    padding-left: 10px !important;
  }
  #pointer-span {
    color: $dark-green;
    font-weight: bold;
  }
  .bg-cover {
    min-height: 96%;
    width: 100%;
    background: #edf1f0;
    overflow: hidden;
    position: relative;
    @media screen and (min-width: map-get($grid-breakpoints, "md")) {
      border-radius: 12px;
      margin-top: 1rem;
      margin-left: 1rem;
      margin-right: 1rem;
    }
  }
  .page-wrapper {
    width: 100%;
    .desktop-layout {
      display: block;
      width: auto;
      margin: 0;
      background: white;
      border-radius: 12px;
      margin: 1rem;
      @media screen and (min-width: map-get($grid-breakpoints, "md")) {
        margin: 1rem;
      }
      .add-open-position {
        background: $white;
        width: auto;
        border-radius: 10px 10px 0 0;
        padding: 20px;
        display: flex;
        justify-content: flex-end;
        box-shadow: rgba(100, 100, 111, 0.2) 0 7px 29px 0;
      }
      .open-position-filters {
        display: flex;
        padding: 15px 0;
        background: $background-green;
        .filter {
          display: flex;
          justify-content: center;
          align-items: center;
          cursor: pointer;
          &.positions {
            width: 15%;
          }
          &.candidates {
            width: 16%;
          }
          &.deadline {
            width: 10%;
          }
          .filter-title {
            margin: 0 5px 0 0;
            font-size: 12px;
          }
        }
        .spacer {
          width: 55%;
        }
      }
    }
  }
}

.page-loading {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  img {
    width: 100px;
    height: 100px;
  }
}

.toolbar {
  display: flex;
  width: 100%;
}

.more-options {
  position: relative;
  top: 1rem;
  cursor: pointer;
}
