.coaching-page {
    display: flex;
    flex-direction: column;
    width: 100%;

    .call-to-action-container {
        display: flex;
        justify-content: space-between;
        width: calc(100% - 32px);  
        max-width: 1408px; 
        margin: 0 auto; 
        box-sizing: border-box;  

        .teams-vs-individual {
            display: flex;
            justify-content: flex-start;
        }

        .mazhrBtn {
            align-self: flex-end;
            order: 2; 
        }
    }

    .content-container {
        flex: 1;
    }
}

@media screen and (max-width: 1376px) {
    .coaching-page .call-to-action-container {
      max-width: calc(3 * 340px + 2 * 16px);
    }
  }
  

@media screen and (max-width: 1387px) {
  .coaching-page .call-to-action-container {
    max-width: calc(3 * 340px + 2 * 16px);
  }
}

@media screen and (max-width: 1051px) {
  .coaching-page .call-to-action-container {
    max-width: calc(2 * 340px + 1 * 16px);
  }
}

@media screen and (max-width: 768px) {
    .coaching-page .call-to-action-container {
        flex-direction: column;
        align-items: stretch;
        max-width: none;  

        .teams-vs-individual {
            flex-direction: row; 
            justify-content: space-between;
            align-items: center; 
            width: 100%;

            button.mazhrBtn {
                width: calc(50% - 5px); 
                margin-bottom: 0; 
            }
        }

        .mazhrBtn.light-green,
        .mazhrBtn.default-green {
            width: 100%; 
            margin-top: 10px; 
        }
    }
}
