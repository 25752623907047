.card-row {
  display: flex;
  justify-content: space-between;
}

.custom-card {
    border: 1px solid #8080802b;

    background: #e3e3e3;
    border-radius: 10px;
    overflow: hidden;
    width: 340px;
    font-family: Arial, sans-serif;

    display: flex;
    flex-direction: column;
    height: 100%;
  }

  .card-top {
    position: relative;
  }

  .card-image {
    width: 100%;
    max-height: 200px;
    object-fit: cover;
  }

  .more-info-button {
    position: absolute;
    top: 10px;
    right: 10px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 5px;
    padding: 8px 16px;
    cursor: pointer;
  }

  .card-bottom {
    border-top: none;
    border-radius: 0px 0px 10px 10px;

    display: flex;
    flex-direction: column;
    flex: 1;
  }

  .coach-details {
    padding: 20px;
    display: flex;
    align-items: center;
  }

  .coach-image {
    width: 35px;
    height: 35px;
    border-radius: 6%;
    margin-right: 10px;
    object-fit: cover;
    display: block;
  }

  .coach-info {
    flex-grow: 1;
  }

  .coaching-type {
    font-weight: bold;
    margin: 0;
  }

  .coach-name {
    margin: 0;
    font-weight: 300;
    font-size: 12px;
  }

  .description {
    font-style: italic;
    padding: 0 20px 0 20px;
    font-size: 12px;
    line-height: 1rem!important;
    color: #131515;
    font-weight: 300;
    margin-bottom: 0px;
  }

  .price-info {
    display: flex;
    border-top: 1px solid #8080802b;
    padding: 0px 20px 0px 20px;
    justify-content: space-between;
    flex-grow: 1;
    position: relative;
    .coaching-type {
        display: flex;
        justify-content: center;
        align-items: center;
        border-right: 1px solid #8080802b;
        font-size: 13px;
        font-weight: 300;
        line-height: 1rem;
        text-align: center;
        padding: 10px;
    }
    .coaching-type::after {
      content: "";
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;
      border-right: 1px solid #8080802b;
  }
    .coaching-price {
        padding: 10px;
        justify-content: center;
        align-items: center;
        display: flex;
        font-size: 14px;
        font-weight: 600;
        margin-bottom: 0;
    }
  }


  .price-details {
    display: flex;
    flex-direction: column;
    flex: 1;
  }
