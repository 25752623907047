.auth-page-wrapper {
  width: 100%;
  background-color: $white;

  background-image: url("../assets/images/backgrounds/onboarding-background.png");
  background-repeat: no-repeat;
  background-size: auto;
  background-position-x: right;
  display: flex;
  justify-content: center; /* Center align horizontally */
  align-items: center; /* Center align vertically */
  height: 100vh; /* Set height to fill the viewport vertically */


  .form-wrapper {
    z-index: 9;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    width: 100%;
    height: fit-content;
    margin: auto;
    padding: 50px 20px;
    min-width: 500px;
    border-radius: 15px;
    box-shadow: 0 -5px 16px 4px rgba(0, 0, 0, 0.1);
    background: $white;

    @media screen and (min-width: map-get($grid-breakpoints, "md")) {
      width: 750px;
      padding: 50px;
      border-radius: 15px;
      box-shadow: 5px 0 16px 4px rgba(0, 0, 0, 0.1);
    }

    .login-form-text-left {
      text-align: left;
    }

    .back-to-login-link {
      text-align: left;
      font-size: 16px;
      font-weight: bolder;
    }

    .form {
      width: 100%;
      min-width: 400px;
      max-width: 600px;
      position: relative;
      margin: 50px auto;
      .recover-password {
        bottom: 86px;
        right: 0;
      }
      .authButtons {
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        align-items: center;
        margin-top: 20px;
        .mazhrBtn {
          width: 100%;
          margin-bottom: 10px;
          &.full-width {
            width: 100%;
            @media screen and (min-width: map-get($grid-breakpoints, "md")) {
              width: 50%;
            }
          }
        }
      }
    }
    .rememberMeWrapper {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 20px;
    }
    .title {
      font-size: 2.5rem;
      font-weight: 400;
      margin-bottom: 20px;
      color: $default-green;
      line-height: 3rem !important;
    }
  }

  .form-wrapper-wide {
    width: 90%;
    margin: 100px 5%;
  }

  .auth-social-login {
    margin: 20px auto;
  }
  .social-icon {
    margin: 2px;
  }
}
