.talents-no-data {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .no-data-illustration {
        max-width: 100%;
        margin-bottom: 30px;
    }
    .description {
        width: 400px;
    }
}

.filters {
    padding: 10px;
    background-color: white;
    @media screen and (min-width: map-get($grid-breakpoints, 'sm')) {
        padding: 20px;
    }
    @media screen and (min-width: map-get($grid-breakpoints, 'md')) {
        padding: 40px 20px;
        margin: 1rem;
        border-radius: 12px;
    }
    @media screen and (min-width: map-get($grid-breakpoints, 'lg')) {
        padding: 20px;
    }
    .custom-filters {
        display: flex;
        justify-content: center;
        @media screen and (min-width: map-get($grid-breakpoints, 'md')) {
            justify-content: space-between;
        }
        .filter {
            cursor: pointer;
            background: $light-grey;
            padding: 5px 10px;
            border-radius: 7px;
            display: flex;
            align-items: center;
            width: 50%;
            display: flex;
            justify-content: center;
            @media screen and (min-width: map-get($grid-breakpoints, 'md')) {
                width: auto;
            }
            &:first-child {
                margin-right: 10px;
            }
            &.active, &:hover {
                background: $light-green;
                color: $default-green;
            }
        }
    }
    .sort-filters {
        display: flex;
        .MuiBox-root {
            width: 100%;
            @media screen and (min-width: map-get($grid-breakpoints, 'md')) {
                width: 250px;
            }
        }
        button {
            margin-right: 10px;
            &.btn-with-icon {
                margin: 0 10px;
                &:last-child {
                    margin: 0;
                }
                .listView::before {
                    content: url('../assets/images/icons/listViewWhite.svg');
                    padding-right: 5px;
                    display: inline-block;
                    height: 100%;
                    vertical-align: middle;
                }
                .gridView {
                    &::before {
                        content: url('../assets/images/icons/gridViewGreen.svg');
                        padding-right: 5px;
                        display: inline-block;
                        height: 100%;
                        vertical-align: middle;
                    }
                }
                &:hover {
                    .gridView {
                        &::before {
                            content: url('../assets/images/icons/gridViewWhite.svg');
                        }
                    }
                }
            }
        }
    }
}
.target-position-simple {

    flex-direction: column;
    @media screen and (min-width: map-get($grid-breakpoints, 'md')) {
        flex-direction: row;
    }
    .MuiBox-root {
        width: 100%;
        @media screen and (min-width: map-get($grid-breakpoints, 'md')) {
            width: 250px;
        }
    }
    .input {
        margin-top: 10px;
        @media screen and (min-width: map-get($grid-breakpoints, 'md')) {
            &:first-child {
                margin-right: 10px;
            }
        }
        .MuiBox-root {
            @media screen and (max-width: map-get($grid-breakpoints, 'md')) {
                width: 100%;
            }
        }
    }
}
.target-position {
    display: flex;
    justify-content: center;
    align-items: center;
    background: $light-green;
    padding: 100px 0;
    .filters-wrapper {
        width: 20%;
    }
    .form-field{
        max-width: 500px;
    }
}
.matches-actions {
    display: flex;
    justify-content: space-between;
    padding: 0 20px;
    @media screen and (min-width: map-get($grid-breakpoints, 'lg')) {
        padding-right: 20px;
        padding-left: 40px;
    }
}
.talents {
    background-color: #f0f4f4;
    display: grid;
    grid-template-columns: 0.8fr 1.2fr;
    .talent-items {
        background-color: $white;
        margin-top: 20px;
        .talent-items-wrapper {
            border: 1px solid $lighter-grey;
        }
    }
    .talent-details {
        overflow-y: scroll;
        overflow-x: hidden;
        margin-top: 20px;
        background-color: #f0f4f4;
        padding: 0 0 0 15px;
        border-top: 1px solid $lighter-grey;
        border-right: 1px solid $lighter-grey;
        .actions {
            display: flex;
            justify-content: flex-end;
            .icon {
                margin-right: 10px;
            }
        }
        .basic-info {
            display: flex;
            font-size: 14px;
            .image {
                .user-image {
                    width: 120px;
                    height: 120px;
                    border-radius: 12px;
                    margin-right: 20px;
                }
                .image-replacement {
                    width: 95px;
                    height: 95px;
                    border-radius: 50%;
                    background: $default-green;
                    margin-right: 20px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    &.green {
                        background: $default-green;
                    }
                    &.yellow {
                        background: $default-yellow;
                    }
                    &.pink {
                        background: $default-pink;
                    }
                    &.purple {
                        background: $default-purple;
                    }
                    .initials {
                        color: $white;
                        margin: 0;
                        text-transform: uppercase;
                    }
                }
            }
            .description {
                .match-heading {
                    display: flex;
                    justify-content: space-between;
                    .title {
                        text-transform: capitalize;
                        display: flex;
                        align-items: center;
                        margin-bottom: 10px;
                        .name {
                            margin-right: 15px;
                        }
                        .match {
                            color: $default-green;
                            &.green {
                                color: $default-green;
                            }
                            &.yellow {
                                color: $default-yellow;
                            }
                            &.pink {
                                color: $default-pink;
                            }
                            &.purple {
                                color: $default-purple;
                            }
                        }
                    }
                }
            }
        }
        .competences-motivation-section {
            margin-top:15px;
            display: flex;
            justify-content: space-between;
            .competences {
                width: 100%;
                margin-right: 10px;
            }
            .motivation {
                width: 100%;
                margin-left: 10px;
            }
            .stats {
                .accordion {
                    .sendToTests {
                        background-color: $lightest-pink;
                        padding: 20px;
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        align-items: center;
                        border-radius: 5px;
                    }
                    .accordion-item {
                        .accordion-header {
                            .accordion-button {
                                background-color: $white;
                                padding: 10px 20px;
                                &:focus {
                                    border: none!important;
                                    box-shadow: none!important;
                                }
                                &:focus-visible {
                                    outline: none!important;
                                }
                                .accordion-header-wrapper {
                                    width: 100%;
                                    display: flex;
                                    justify-content: space-between;
                                    margin-right: 20px;
                                    p {
                                        color: $black;
                                    }
                                }
                                &::after {
                                    background-image: url('../assets/images/icons/arrowDownGrey.svg') !important;
                                    height: 8px;
                                }
                            }
                        }
                        .accordion-collapse {
                            .accordion-body {
                                padding: 0;
                                .description {
                                    padding: 15px;
                                    &.yes {
                                        background: $lightest-green;
                                    }
                                    &.no {
                                        background: $lightest-pink;
                                    }
                                    .bar {
                                        width: 30%;
                                        height: 10px;
                                        border-radius: 15px;
                                        margin-bottom: 15px;
                                        &.yes {
                                            background: $default-green;
                                        }
                                        &.no {
                                            background: $default-pink;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
            .show-more-link {
                margin-top: 5px;
                width: 100%;
                display: flex;
                justify-content: flex-end;
            }
        }

        .compability-section {
            margin-top: 15px;
            background-color: $white;
            padding: 20px;
            border-radius: 12px;
            margin-bottom: 15px;
            width: 50%;
            .accordion-main {
                .stat {
                    display: grid;
                    grid-row-gap: 25px;
                    grid-column-gap: 50px;
                    grid-template-columns: 1fr 1fr;
                    grid-auto-flow: dense;

                    .description {
                        display: flex;
                        justify-content: space-between;
                    }
                }
                .sendToTests {
                    background-color: $lightest-pink;
                    padding: 20px;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    border-radius: 5px;
                }
            }

            .show-more-link {
                width: 100%;
                display: flex;
                justify-content: flex-end;
                margin-top: 20px;
            }
        }
    }
}

.actions {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    img {
        cursor: pointer;
        width: 15px;
        height: 15px;
        margin-right: 5px;
        &:last-child {
            margin-right: 5px;
        }
    }
    .copy-icon {
        width: 20px;
        height: 20px;
    }
}
.invite-pending{
    margin-right:10px;
}

.talent-item {
    cursor: pointer;
    display: grid;
    grid-template-columns: 0.3fr 1fr 1fr 1fr;
    border-top: 1px solid $lighter-grey;
    width: 100%;
    @media screen and (min-width: map-get($grid-breakpoints, 'lg')) {
        padding: 15px;
    }
    &:first-child {
        border-top: 0;
    }
    &:hover {
        @media screen and (min-width: map-get($grid-breakpoints, 'lg')) {
            background-color: $light-green;
            border: 2px solid $light-green;
        }
    }
    &.active{
        @media screen and (min-width: map-get($grid-breakpoints, 'lg')) {
            background: $light-green;
        }
    }
}

.MuiPaper-root {
    margin: 0!important;
}

.talents-mobile {
    @media screen and (min-width: map-get($grid-breakpoints, 'xxl')) {
        display: none;
    }

}

// move to it's own css
.pages-navigation {
    display: flex;
    border-bottom: 1px solid $light-grey;
    margin-top: 20px;
    .pages-nav-item {
        cursor: pointer;
        color: $black;
        padding: 0 15px 15px 15px;
        margin: 0 10px 0 10px;
        &:hover {
            border-bottom: 2px solid $default-green;
        }
        &.active {
            border-bottom: 2px solid $default-green;
            font-weight: bold;
        }
    }
}
.picklist-div {
    display: flex;
    justify-content: flex-end;
    height: 0;
}


.p-badge.p-badge-xl {
    line-height: 3rem !important;
}

.yellow-initials{
    background: $default-yellow;
    color: white;
    border-radius: 50%;
    padding: 0.5rem;
    position: relative;
    top: 0.5em;
    margin-right: 1em;
}

.pink-initials{
    background:  $default-pink;
    color: white;
    border-radius: 50%;
    padding: 0.5rem;
    position: relative;
    top: 0.5em;
    margin-right: 1em;
}

.green-initials{
    background: $default-green;
    color: white;
    border-radius: 50%;
    padding: 0.5rem;
    position: relative;
    top: 0.5em;
    margin-right: 1em;
}
.team-simulator-button {
    border: none;
    background-color: transparent;
    font-size: small;
    color: #00b0a0;
    position: absolute;
    right: 40px;
    top: 55px;
}
.add-filter-list {
    padding: 0;
    opacity: 1;
    transform: none;
    min-width: 250px;
    transition: opacity 262ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, transform 174ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    top: 0;
    left: 0;
    transform-origin: 125px 0;
    border-radius: 4px;
    box-shadow: 0 5px 5px -3px rgba(0,0,0,0.2), 0 8px 10px 1px rgba(0,0,0,0.14), 0 3px 14px 2px rgba(0,0,0,0.12);
    position: relative;
    overflow-y: auto;
    overflow-x: hidden;
    min-height: 16px;
    z-index: 10000;
    max-height: 75vh;
    outline: 0;
    background-color: #fff;
}
.p-multiselect.p-component.p-inputwrapper.p-focus.p-inputwrapper-focus {
    border: 2px solid #00B0A0 !important;
    box-shadow: none !important;
  }
.p-multiselect:not(.p-disabled):hover {
    border-color: #000000 !important;
}
.p-checkbox .p-checkbox-box.p-highlight {
    border-color: #00B0A0 !important;
    background: #00B0A0 !important;
}
.p-multiselect-panel .p-multiselect-items .p-multiselect-item.p-highlight {
    background-color: rgba(0, 176, 160, 0.08) !important;
    color: #000000 !important
  }

.p-multiselect-panel .p-multiselect-items .p-multiselect-item:focus {
    box-shadow: none !important;
}

.css-jhy6v-MuiButtonBase-root-MuiCheckbox-root{
    padding : 1px 5px 1px 5px !important;
}

.p-multiselect-filter{
    height : 35px;
}
.justify-content-space-evenly {
    justify-content: space-evenly;
}

.p-dialog-content {
    padding: 0px !important;
}

.grey-text {
    color: $grey;
    margin: 0px 20px;
    font-weight: 300;
    padding : 0px;
    word-wrap: break-word;
}

.black-text {
    color: $black;
}

.p-dialog-header{
    background: white !important;
}
.p-dialog-content{
    background: white !important;
}
.p-dialog-footer{
    background: white !important;
}

@media screen and (max-width: map-get($grid-breakpoints, 'xxl')) {
    .accordion-button {
        padding: 10px 5px !important;
        .accordion-header-wrapper {
            margin-right: 10px !important;
        }
    }

}
