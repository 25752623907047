.messages-page-wrapper {
  padding: 3%;
  display: flex;
  flex-wrap: wrap;

  .messages-page-section {
    width: calc(50% - 10px); /* Subtracting margin */
    height: fit-content;
    box-sizing: border-box;
  }

  .team-list {
    border-collapse: collapse;

    .toggle-button {
      background-color: $default-green;
      color: white;
      border: none;
      padding: 8px 12px;
      text-align: center;
      text-decoration: none;
      display: inline-block;
      font-size: 14px;
      margin: 4px 2px;
      cursor: pointer;
      border-radius: 4px;
    }

    .active {
      background-color: #e0f4f1;
      border: 1px solid #dadbd8;
      margin-bottom: 10px;
      border-radius: 10px;
    }
  }

  .team-list-row {
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding-left: 20px;

    & div {
      &:first-child {
        flex-grow: 1;
      }
      &:nth-child(2),
      &:nth-child(3) {
        width: 100px;
      }
    }

    .team-list-filter {
      width: 100px;
      text-align: left;
    }
  }

  .team-list-head {
    background-color: $lighter-grey;
    font-weight: bold;
    min-height: 50px;
    width: 100%;
    margin-bottom: 20px;
  }

  .team-list-body {
    border: 1px solid #dadbd8;
    border-radius: 10px;
    margin-top: 20px;

    p {
      margin: 20px 0;
    }

    &:first-child {
      margin-top: 0;
    }

    &:hover {
      background-color: #f2f2f2;
    }

    &.expanded {
      background-color: $light-green;
      border-radius: 10px 0 0 10px;
    }

    &.desktop-focus {
      @media (min-width: 801px) {
        background-color: $light-green;
        border-radius: 10px 0 0 10px;
      }
    }
  }

  .expanded-row {
    width: 100%;
    display: none;
  }

  .about-team {
    display: block;
  }

  .high-light {
    padding: 20px;
    border: 1px solid $default-green;
    margin-bottom: 20px;

    .high-light-list-item {
      margin: 5px 0;
      img {
        width: 15px;
        margin-right: 15px;
      }
    }
  }

  @media (max-width: 800px) {
    .messages-page-section {
      width: 100%;
    }

    .about-team {
      display: none;
    }

    .expanded-row {
      display: block;
    }
  }
}

.messages-page-dialog-wrapper {
  .dialog-list-item {
    display: block;
    margin: 10px 0;
    input,
    span {
      display: inline-block;
      vertical-align: middle;
    }
    .list-item-checkbox {
      margin-right: 10px;
      width: 20px;
      height: 20px;
    }
  }

  .dialog-button {
    margin-top: 20px;

    background-color: white;

    cursor: pointer;
    transition: color 0.3s, background-color 0.3s;
  }

  .accept {
    color: $default-green;
    border: 1px solid $default-green;
    &:hover {
      color: white;
      background-color: $default-green;
    }
  }

  .reject {
    color: red;
    border: 1px solid red;
    &:hover {
      color: white;
      background-color: red;
    }
  }
}
