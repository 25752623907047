.sidebar-wrapper {
  .sidebar {
    position: fixed;
    display: flex;
    flex-direction: column;
    background: $white;
    width: 60px;
    z-index: 9;
    min-height: 100vh;
    height: 100%;
    justify-content: space-between;
    align-items: center;
    .primary-menu {
      margin-top: 15px;
    }
    .primary-menu,
    .secondary-menu {
      .nav-item {
        cursor: pointer;
        .link {
          padding: 10px 0;
          color: $black;
          width: 40px;
          height: 40px;
          display: flex;
          justify-content: center;
          align-items: center;
          .navIcon {
            &.active {
              background: $light-green;
              border-radius: 5px;
            }
          }
          &.logo {
            margin-bottom: 20px;
            .company-logo {
              width: 100%;
            }
          }
          &.active {
            background: $light-green;
            border-radius: 5px;
            .navIcon {
              background: $light-green;
            }
          }
        }
        .subMenuItemsWrapper {
          display: flex;
          flex-direction: column;
          .subMenuItem {
            white-space: nowrap;
            color: $grey;
            margin-bottom: 10px;
            .indicators {
              display: flex;
              justify-content: center;
              align-items: center;
            }
          }
        }
      }

      .locked {
        pointer-events: none;
      }
    }

    .hidden-sidebar {
      position: fixed;
      width: 0;
      background-color: $white;
      transition: width 0.5s;
      overflow: hidden;
      left: 60px;
      display: flex;
      flex-direction: column;
      z-index: 9;
      min-height: 100vh;
      height: 100%;
      justify-content: space-between;
      .primary-menu {
        margin-top: 15px;
      }
      .primary-menu,
      .secondary-menu {
        .nav-item {
          cursor: pointer;
          .link {
            padding: 10px 0;
            color: $black;
            width: auto;
            height: 40px;
            justify-content: flex-start;
            white-space: nowrap;
            &.logo {
              margin-bottom: 20px;
              .company-logo {
                width: 70px;
              }
            }
            .submenu {
              width: 100%;
            }
            .navItemTitle {
              width: 100%;
              display: flex;
              align-items: center;
              justify-content: space-between;
              padding-right: 10px;
              color: $black;
              cursor: pointer;
              .submenuTogglerClosed {
                width: 10px;
                height: 7px;
              }
            }
            &.active {
              background: $white;
              border-right: 2px solid $default-green;
              border-radius: 0;
            }
          }
          .subMenuItemsWrapper {
            display: flex;
            flex-direction: column;
            .subMenuItem {
              white-space: nowrap;
              color: $grey;
              margin-bottom: 10px;
              &:hover,
              &.selected {
                color: $medium-green;
              }
            }
          }
        }
      }
    }
  }

  .sidebar-spacer {
    width: 60px;
    transition: width 0.5s;
  }
}

.sidebar-wrapper:hover .sidebar-spacer {
  width: 205 px;
  box-shadow: 10px 4px 75px 10px rgba(0, 0, 0, 0.41);
  -webkit-box-shadow: 10px 4px 75px 10px rgba(0, 0, 0, 0.41);
  -moz-box-shadow: 10px 4px 75px 10px rgba(0, 0, 0, 0.41);
}

.sidebar:hover .hidden-sidebar {
  width: 180px;
}

.hidden-sidebar {
  width: 0;
}
