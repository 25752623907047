.onboarding-stepper-wrapper {
  width: 100%;
  text-align: center;
  padding: 30px 0;

  .onboarding-stepper-step {
    display: inline-block;
    margin: 0 10px;
  }

  .onboarding-stepper-step-number {
    display: inline-block;
    width: 30px;
    height: 30px;
    background-color: $light-grey;
    color: $default-green;
    opacity: 0.5;
    border-radius: 50%;
    padding: 5px;
    text-align: center;
    margin-right: 5px;
  }

  .onboarding-stepper-step-number-active {
    display: inline-block;
    width: 30px;
    height: 30px;
    background-color: $default-green;
    color: $white;
    border-radius: 50%;
    padding: 5px;
    text-align: center;
    margin-right: 5px;
  }

  .onboarding-stepper-step-label {
    color: $default-green;
    opacity: 0.5;
  }

  .onboarding-stepper-step-label-active {
    color: $default-green;
  }

  .onboarding-stepper-step-arrow {
    margin-left: 10px;
  }
}
