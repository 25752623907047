
.form-field {
    margin: 10px 0 30px 0;
    &.mazhrCheckbox {
        margin: 0;
    }
    label {
        color: $grey;
        margin-bottom: 10px;
    }
    .input-wrapper {
        display: flex;
        justify-content: space-between;
        border-bottom: 1px solid $grey;
        padding-bottom: 5px;
        cursor: text;
        input {
            border: none;
            width: 100%;
            background-color: transparent;
            &:focus-visible {
                outline: none;
            }
        }
        .icon {
            opacity: 0.5;
            &.active {
                opacity: 1;
            }
        }
    }
}

.form-group {
    .form-control {
        font-size: 14px;
        border: none;
        border-bottom: 1px solid $light-grey;
        border-radius: 0;
        padding-left: 0!important;
    }
}


[contenteditable=true]:empty:before{
    content: attr(placeholder);
    color: $grey;
}

[contenteditable=true] {
    &:focus-visible {
        outline: none!important;
    }
}


// select

select {
    -moz-appearance:none;
    -webkit-appearance:none;
    appearance:none;
    outline: none;
    background-image: url("../assets//images/icons/arrowDown.svg");
    background-repeat: no-repeat;
    background-position-x: 100%;
    background-position-y: 5px;
}

.select::after {
    content: "";
    width: 0.8em;
    height: 0.5em;
    background-color: var(--select-arrow);
    clip-path: polygon(100% 0%, 0 0%, 50% 100%);
  }

// remove blue background from autofill

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  -webkit-box-shadow: 0 0 0 1000px $white inset !important;
}
