.assessment-page-wrapper {
  padding: 3%;
  .assessment-progress {
    width: 100%;
    height: 60px;
    > div {
      float: right;
      text-align: left;
      .assessment-progress-slider {
        width: 250px;
        display: flex;
        .assessment-progress-bar {
          margin: 10px 10px 0 0;
          width: 200px;
          height: 8px;
          background-color: $light-grey;

          .assessment-progress-bar-fill {
            height: 8px;
            background-color: $default-purple;
          }
        }
        .assessment-progress-bar-indicator {
          display: inline;
        }
      }
    }
  }

  .assessment-card-wrapper {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    .assessment-card {
      margin: 10px 20px 10px 0;
      width: 400px;
      min-height: 425px;
      padding: 20px;
      background-color: $white;
      border-radius: 12px;
      border: 1px solid $light-grey;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      text-align: left;
      position: relative;

      .assessment-card-header {
        min-height: 200px;
        height: fit-content;
        padding: 20px;
        background-repeat: no-repeat;
        background-size: cover;
        position: relative;
        color: $white;
        border-radius: 10px;

        > h3 {
          margin: 20px 50px 20px 0;
        }

        > h5 {
          margin: 20px 0;
        }
      }

      .assessment-card-notification-wrapper {
        position: absolute;
        top: 10px;
        right: 10px;
      }
      .required-note {
        display: flex;
        color: red;
        cursor: pointer;
        background-color: $white;
        padding: 5px;
        border-radius: 5px;
        justify-content: center;
      }

      .assessment-card-body {
        min-height: 220px;
        height: fit-content;
        padding: 10px;

        .assessment-card-cta-wrapper {
          display: block;
          margin-top: 20px;
          position: absolute;
          bottom: 20px;
        }
      }

      .assessment-completed-card-body {
        height: fit-content;
        padding: 10px;

        .assessment-card-cta-wrapper {
          display: block;
          margin-top: 20px;
          position: absolute;
          bottom: 20px;
        }
      }
    }
  }

  .required-note-icon {
    color: red;
    cursor: pointer;
  }

  .assessment-page-tag-wrapper {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    .assessment-page-tag {
      margin: 10px 10px 0 0;
      padding: 10px;
      background-color: $default-purple;
      color: $white;
      border-radius: 10px;
    }
  }

  .test-instruction {
    display: flex;
    flex-direction: column;
    width: 100%;

    .test-instruction-column {
      width: 100%;
      padding: 10px 20px;
    }

    .test-instruction-column-left {
      background-color: $light-yellow;
      margin: 0 0 20px 0;
      border-radius: 10px;
    }

    .test-instruction-list-item {
      list-style-type: circle;
    }

    .test-instruction-select-language {
      margin: 20px 0;
    }

    .test-instruction-heading {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: left;
    }

    @media screen and (min-width: map-get($grid-breakpoints, "sm")) {
      .test-instruction-heading {
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
      }
    }

    @media screen and (min-width: map-get($grid-breakpoints, "lg")) {
      flex-direction: row;
      .test-instruction-column {
        width: 50%;
        padding-right: 20px;
      }
      .test-instruction-column-left {
        margin: 0 20px 0 0;
      }
    }
  }

  .test-measure-importance {
    display: flex;
    flex-direction: column;
    width: 100%;

    .test-measure-importance-column {
      width: 100%;
      padding: 5px 20px;
    }

    .test-measure-importance-column-left {
      background-color: $light-yellow;
      margin: 0 0 20px 0;
      border-radius: 10px;
    }

    .test-measure-importance-heading {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: left;
    }

    @media screen and (min-width: map-get($grid-breakpoints, "sm")) {
      .test-measure-importance-heading {
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
      }
    }

    @media screen and (min-width: map-get($grid-breakpoints, "lg")) {
      flex-direction: row;
      .test-measure-importance-column {
        width: 50%;
        padding-right: 20px;
      }
      .test-measure-importance-column-left {
        margin: 0 20px 0 0;
      }
    }
  }
}
