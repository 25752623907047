
* {
    font-family: 'Raleway';
}
.App {
    font-size: 14px;
    overflow-x: hidden;
    letter-spacing: 0.5px;

    .font-rounded {
        font-family: 'MPLUSRounded1c', sans-serif;
    }

    h1 {
        line-height: 2.8rem !important;
        &.l {
            font-size: 40px;
            line-height: 3rem !important;
        }
        &.xl {
            font-size: 45px;
            line-height: 3.5rem !important;
        }
        &.xxl {
            font-size: 50px;
            line-height: 4rem !important;
        }
        &.xxxl {
            font-size: 60px;
            line-height: 4.5rem !important;
        }
    }

    h2, 
    h3 { 
        line-height: 2.3rem!important;
        &.l {
            font-size: 30px;
        }
    }

    h4 {
        line-height: 1.9rem!important;
    }
    h5 {
        line-height: 1.8rem!important;
    }

    h6 {
        line-height: 1.7rem!important;
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    p,
    a,
    span,
    label {
        letter-spacing: 1px;
        &.thin {
            font-weight: 100;
        }
        &.extra-light {
            font-weight: 200;
        }
        &.light {
            font-weight: 300;
        }
        &.regular {
            font-weight: 400;
        }
        &.medium {
            font-weight: 500;
        }
        &.semi-bold {
            font-weight: 600;
        }
        &.bold {
            font-weight: 700;
        }
        &.extra-bold {
            font-weight: 800;
        }
        &.center {
            text-align: center;
        }  
    }

    p,
    a,
    span {
        line-height: 1.3rem;
        &.s {
            line-height: 1.5rem;
            font-size: 12px;
        }
        &.xs {
            line-height: 1.3rem;
            font-size: 10px;
        }
        &.m {
            font-size: 16px;
        }
        &.l {
            font-size: 18px;
        }
        &.xl {
            font-size: 20px;
        }
        &.grey {
            color: $grey;
        }
    }

    a {
        text-decoration: none;
        color: $default-green;
        &:hover {
            color: $medium-green;
        }
        &.white {
            color: $white;
        }
        &.grey {
            color: $grey;
        }
        &.underline {
            &:hover {
                text-decoration: underline;
            }
        }
    }

    // custom sizing for anything

    .rem1 {
        font-size: 1rem;
    }
    .rem2 {
        font-size: 1rem;
    }

    // Text decoration
    .italic {
        font-style: italic;
    }
    .upper {
        text-transform: uppercase;
    }
    .lower {
        text-transform: lowercase;
    }

}



// DELETE

.flex-container {
    border: 1px solid red;
    display: flex;
    .flex-item {
        flex: 1 1 0;
        min-width: 0;
    }
}