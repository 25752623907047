.auth-desktop-decoration {
  width: 100%;
  position: relative;
  height: 100px;
  .decoration-company-logo {
    height: 60px;
    display: block;
    margin: 20px;
    // float: left;
    left: 50%;
    transform: translateX(-50%);
    position: absolute;
  }
}
