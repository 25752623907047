.card-grid-container {
  display: flex;
  justify-content: center; 
  align-items: center; 
  min-height: 100vh; 
}

.card-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(340px, 1fr));
  grid-gap: 16px;
  padding-top: 16px;
  margin: 0 auto;
  justify-content: center;
  width: calc(100% - 32px);
  max-width: 1408px;  
  box-sizing: border-box;
}

/* For tablets and mobile */
@media screen and (max-width: 1387px) { 
  .card-grid {
    grid-template-columns: repeat(3, 340px);
    max-width: none;  
  }
}

@media screen and (max-width: 1051px) { 
  .card-grid {
    grid-template-columns: repeat(2, 340px);
    max-width: none;
  }
}

@media screen and (max-width: 695px) { 
  .card-grid {
    grid-template-columns: repeat(1, 340px);
    max-width: none;
  }
}
