.mobile-nav {
  .primary-menu,
  .secondary-menu {
    display: flex;
    .nav-item {
      > a {
        color: black;
      }
      .link {
        padding: 15px;
        img {
          width: 20px;
          height: 20px;
        }
      }
    }

    .locked {
      pointer-events: none;
    }
  }
  .primary-menu {
    box-shadow: rgba(149, 157, 165, 0.2) 0 8px 24px;
    background-color: $white;
    justify-content: left;
    align-items: center;
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 10px;
    margin: 0;
    z-index: 9;
    @media screen and (min-width: map-get($grid-breakpoints, "sm")) {
      justify-content: space-around;
      padding: 20px 100px;
    }
    .nav-item {
      margin: 0 10px;
      &:nth-child(3) {
        .link {
          img {
            width: 40px;
            height: 40px;
          }
          &.active {
            background: none;
          }
        }
      }

      .link {
        padding: 15px;
        &.hamburger {
          background: none;
          border: none;
        }
        &.active {
          background: $lightest-green;
          border-radius: 5px;
        }
      }
    }
  }
  .secondary-menu {
    position: relative;
    padding: 0 0 15px 0;
    z-index: 5;
    flex-direction: column;
    width: 100vw;
    position: fixed;
    bottom: -1000px;
    transition: 2s;
    &.active {
      bottom: 84px;
      opacity: 1;
      transition: 800ms;
    }
    .nav-item {
      cursor: pointer;
      box-shadow: rgba(60, 64, 67, 0.3) 0 1px 2px 0,
        rgba(60, 64, 67, 0.15) 0 1px 3px 1px;
      background: $white;
      margin: 3px 10px;
      border-radius: 5px;
      .link {
        display: flex;
        color: $black;
        text-transform: capitalize;
        img {
          margin-right: 10px;
          padding: 12px;
          width: 45px;
          height: 45px;
        }
        &.active {
          img {
            background: $lightest-green;
            border-radius: 5px;
          }
        }
      }
    }
  }
}
